import React from "react"
import styled from "styled-components"
import { useEffect, useState } from "react"
import { Link } from "gatsby"
import { checkStatus } from "../helpers/dateFormating"
import { useContext } from "react"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  transform: translateX(10%);
  @media (max-width: 1124px) {
    transform: translateX(5%);
    height: max-content;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`
const Container = styled.div`
  display: flex;
  width: 80%;
  transform: translateX(10%);
  @media (max-width: 600px) {
    transform: translateX(5%);
    width: 100%;
  }
`
const Text = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #111111;
  margin: 4px 20px;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const Status = styled.span`
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${({ active }) => (active ? "#00ca42" : "#e44331")};
  margin-left: 5px;
  @media (max-width: 900px) {
    margin-left: 0px;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
  a {
    margin: 0px 5px;
    font-weight: normal;
    color: #007de6;
    display: inline-block;
    @media (max-width: 900px) {
      margin: 0px;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  height: 52px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 52px;
  color: #000000;
  margin: 0px 15px;
  margin-top: 24px;
  margin-bottom: 12px;

  @media (max-width: 480px) {
    width: 100%;
    font-size: 32px;
    line-height: 32px;
    margin-bottom: -12px;
  }
`
function AccountStatus() {
  //global context
  const { user, userStatus, setUserStatus } = useContext(GlobalAuthContext)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (checkStatus() === true && userStatus === undefined) {
      setUserStatus(true)
    } else if (checkStatus() === false && userStatus === undefined) {
      setUserStatus(false)
    }
  }, [userStatus])

  useEffect(() => {
    setIsLoading(false)
  }, [])

  if (isLoading) {
    return null
  }
  return (
    <Holder>
      <Container>
        <Title>
          Welcome, <span className="username">{user}!</span>
        </Title>
      </Container>
      <Container>
        <Text>
          Account Status:
          <Status active={userStatus}>
            {userStatus ? (
              <>Active</>
            ) : (
              <>
                Inactive <Link to="/checkout">Reactivate Account</Link>
              </>
            )}
          </Status>
        </Text>
      </Container>
    </Holder>
  )
}
export default AccountStatus

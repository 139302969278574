import axios from "axios"

// function that update customer profile only if user = customer and only if there is no first and last name
export function updateCustomer(obj) {
  if (obj.userEmail === obj.customerEmail) {
    axios
      .patch(
        `http://backend.pdfpro.com/api/license/customers/${obj.customerID}`,
        {
          first_name: obj.name,
          last_name: obj.lname,
        }
      )
      .then(res => console.log(res))
      .catch(err => console.log(err))
  } else {
    return
  }
}

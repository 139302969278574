import React from "react"
import { Router, Redirect } from "@reach/router"
import Dashboard from "./app/dashboard"
import ManageUsers from "./app/manage-users"
import PrivateRoute from "../components/privateRoute"
import Administrator from "./app/administrator"
import ManageSubscription from "./app/manage-subscription"
import Billing from "./app/billing"
import { CheckoutContext } from "../Contexts/CheckoutContext"
import { useState, useEffect } from "react"
import PasswordChange from "./app/password-change"
import api from "../components/api"
import Error from "./404"
import styled from "styled-components"
import Seo from "../components/seo"
//Global context
import { GlobalAuthContext } from "../Contexts/GlobalAuthContext"
import { useContext } from "react"
import DasboardMenu from "../components/dashboardMenu"
import { navigate } from "gatsby"
//shared
import InsertName from "../components/shared/insertName"
import axios from "axios"
import { billing, checkStatus } from "../components/helpers/dateFormating"
import Loader from "../components/shared/loader"

//special element that regulates that if user clicks outside of dropdown button that button closes
const Special = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: ${({ toggle }) => (toggle === true ? "block" : "none")};
  background-color: transparent;
  z-index: 9;
`

const App = () => {
  const [checkoutDetails, setCheckoutDetails] = useState({})
  const [isLogged, setIsLogged] = useState(undefined)

  //ADD HERE FUNCTIONS FROM MANAGE-USERS AND ADMINISTRATOR SO YOU PREFETCH DATA BEFORE LOADING THE PAGE!

  //account state hooks
  // const [planType, setPlanType] = useState(() => "")
  // const [subscriptionPeriod, setSubscriptionPeriod] = useState(() => "")

  //manage-users state hooks - ones that need to be displayed before user interaction
  const [maxUsers, setMaxUsers] = useState(0)
  const [numUser, setNumUser] = useState(0)
  const [primary, setPrimary] = useState(null)
  const [users, setUsers] = useState("")
  const [sync, setSync] = useState(false)
  //info about license
  const [license, setLicense] = useState("")
  //info about licenses that are in use
  const [usedLicenses, setUsedLicenses] = useState("")
  //numberOfLicenses - handle that with custom attributes
  const [numberOfLicenses, setNumberOfLicenses] = useState(null)
  // const [activeLicenses, setActiveLicenses] = useState(0)

  //administrator state hooks - those that change display without interaction
  const [licenseID, setLicenseID] = useState(null)
  const [admin, setAdmin] = useState("")
  const [administratorChange, setAdministratorChange] = useState(0)
  const [synchronize, setSynchronize] = useState(false)
  const [managers, setManagers] = useState([])

  const [checked, setChecked] = useState(false)
  const [updateInfo, setUpdateInfo] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [isOcr, setIsOcr] = useState(false)
  const [prices, setPrices] = useState({})
  const [isProrate, setIsProrate] = useState(false)
  const [updated, setUpdated] = useState(0)
  const [fullPath, setFullPath] = useState("")
  const [accountID, setAccountID] = useState("")
  const [payment, setPayment] = useState("")
  const [card, setCard] = useState({})
  //invoices contains order ids
  const [invoices, setInvoices] = useState([])
  //invoices list contains invoice urls
  const [invoicesList, setInvoicesList] = useState([])
  const [isTrial, setIsTrial] = useState(false)
  const [activeOrder, setActiveOrder] = useState(0)
  // const [isLoadingApp, setIsLoadingApp] = useState(true)
  // //loader state
  // const [loader, setLoader] = useState(undefined)

  //state hook used for license upgrades and quantity changes for users - shared between manage-users and manage-subscriptions
  const [subscriptionID, setSubscriptionID] = useState(undefined)
  // //states that checks number of licenses
  // const [number, setNumber] = useState(null)

  //Global context hook
  const {
    setUser,
    globalPrimary,
    setGlobalPrimary,
    isLoggedIn,
    setIsLoggedIn,
    toggle,
    setToggle,
    number,
    setNumber,
    activeLicenses,
    setActiveLicenses,
    planType,
    setPlanType,
    setLicenses,
    licenses,
    plan,
    setPlan,
    subscriptionPeriod,
    setSubscriptionPeriod,
    isLoadingApp,
    setIsLoadingApp,
  } = useContext(GlobalAuthContext)

  useEffect(() => {
    setIsLoadingApp(true)
    if (planType && card) {
      setTimeout(() => {
        setIsLoadingApp(false)
      }, 1500)
    }
  }, [planType, card])

  function getAccount() {
    let token =
      typeof window !== "undefined"
        ? JSON.parse(localStorage.getItem("isLoggedIn"))
        : ""
    axios
      .get(`https://backend.pdfpro.com/api/subscriptions/${subscriptionID}`, {
        headers: {
          authorization: `Token ${token}`,
        },
      })
      .then(res => {
        setAccountID(res.data.account)
      })
      .catch(err => console.log(err))
  }
  useEffect(() => {
    if (accountID) {
      let token =
        typeof window !== "undefined"
          ? JSON.parse(localStorage.getItem("isLoggedIn"))
          : ""
      axios
        .get(`https://backend.pdfpro.com/api/accounts/${accountID}`, {
          headers: {
            authorization: `Token ${token}`,
          },
        })
        .then(res => {
          setPayment(res.data.url)
          setInvoices(res.data.orders)
        })
        .catch(err => console.log(err))
    }
  }, [accountID])

  function getInvoicesList() {
    let listUrls = []
    let token =
      typeof window !== "undefined"
        ? JSON.parse(localStorage.getItem("isLoggedIn"))
        : ""
    for (let i = 0; i < invoices.length; i++) {
      axios
        .get(`https://backend.pdfpro.com/api/orders/${invoices[i]}`, {
          headers: {
            authorization: `Token ${token}`,
          },
        })
        .then(res => {
          listUrls.push({
            invoiceUrl: res.data.invoiceUrl,
            reference: res.data.reference,
            id: res.data.id,
            time: new Date(res.data.changedDisplay).toLocaleDateString(
              "en-US",
              { year: "numeric", month: "long", day: "numeric" }
            ),
          })
        })
        .catch(err => console.log(err))
    }
    setInvoicesList(listUrls)
  }

  useEffect(() => {
    if (invoices !== [] && !isTrial) {
      getInvoicesList()
    }
  }, [invoices])

  useEffect(() => {
    if (billing() === "Trial" || checkStatus() === false) {
      setIsTrial(true)
    } else {
      setIsTrial(false)
    }
  }, [isTrial])

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("orderID"))) {
      let order = JSON.parse(localStorage.getItem("orderID"))
        ? JSON.parse(localStorage.getItem("orderID")).order_store_id
        : null
      let token = JSON.parse(localStorage.getItem("orderID"))
        ? JSON.parse(localStorage.getItem("isLoggedIn"))
        : null
      if (!order.includes("trial_")) {
        axios
          .get(`https://backend.pdfpro.com/api/orders/${order}`, {
            headers: {
              authorization: `Token ${token}`,
            },
          })
          .then(res => {
            setCard({
              ending: res.data.payment.cardEnding || "Not provided",
              type: res.data.payment.creditCard || "Not provided",
              postal: res.data.address.postalCode || "Not provided",
              tax:
                Math.round((res.data.tax / res.data.total) * 100) ||
                "Not provided",
            })
          })
          .catch(err => console.log(err))
      }
    }
  }, [planType, subscriptionID])

  useEffect(() => {
    if (subscriptionID) {
      getAccount()
    }
  }, [subscriptionID])

  //basic way to manage when to show dashboard menu and which tab is active
  let dash = false
  if (typeof window !== "undefined") {
    if (
      window.location.pathname === "/app/dashboard" ||
      window.location.pathname === "/app/dashboard/"
    ) {
      dash = true
    } else if (
      window.location.pathname === "/app/manage-users" ||
      window.location.pathname === "/app/manage-users/"
    ) {
      dash = true
    } else if (
      window.location.pathname === "/app/administrator" ||
      window.location.pathname === "/app/administrator/"
    ) {
      dash = true
    } else if (
      window.location.pathname === "/app/manage-subscription" ||
      window.location.pathname === "/app/manage-subscription/"
    ) {
      dash = true
    } else if (
      window.location.pathname === "/app/billing" ||
      window.location.pathname === "/app/billing/"
    ) {
      dash = true
    } else {
      dash = false
    }
  }
  useEffect(() => {
    setPrices({
      "Two Year": 96,
      "One Year": 72,
      Monthly: 15,
      "Two Year OCR": 128,
      "One Year OCR": 108,
      "Monthly OCR": 19,
    })
  }, [])
  function givePath() {
    let currentDate = new Date(
      JSON.parse(localStorage.getItem("orderID")).created_at
    )
    let date = new Date(
      JSON.parse(localStorage.getItem("orderID")).validity_period
    )
    let result = Math.ceil(Math.abs(date - currentDate) / (1000 * 60 * 60 * 24))
    if (result > 370 && activeLicenses < 2 && checked === false) {
      return `pdf-two-year`
    } else if (result > 370 && activeLicenses > 1 && checked === false) {
      return `pdf-two-year-multi`
    } else if (result > 370 && activeLicenses > 1 && checked === true) {
      return `pdf-two-year-multi-ocr`
    } else if (result > 370 && activeLicenses < 2 && checked === true) {
      return `pdf-two-year-ocr`
    } else if (
      result < 370 &&
      result > 300 &&
      activeLicenses < 2 &&
      checked === false
    ) {
      return `pdf-one-year`
    } else if (
      result < 370 &&
      result > 300 &&
      activeLicenses > 1 &&
      checked === false
    ) {
      return `pdf-one-year-multi`
    } else if (
      result < 370 &&
      result > 300 &&
      activeLicenses > 1 &&
      checked === true
    ) {
      return `pdf-one-year-multi-ocr`
    } else if (
      result < 370 &&
      result > 300 &&
      activeLicenses < 2 &&
      checked === true
    ) {
      return `pdf-one-year-ocr`
    } else if (result < 300 && activeLicenses < 2 && checked === false) {
      return `pdf-monthly`
    } else if (result < 300 && activeLicenses > 1 && checked === false) {
      return `pdf-monthly-multi`
    } else if (result < 300 && activeLicenses > 1 && checked === true) {
      return `pdf-monthly-multi-ocr`
    } else if (result < 300 && activeLicenses < 2 && checked === true) {
      return `pdf-monthly-ocr`
    }
  }
  useEffect(() => {
    // checkProrate()
    if (localStorage.getItem("orderID")) setFullPath(givePath())
  }, [updated, fullPath, activeLicenses, checked, accountID])
  //plan type check
  useEffect(() => {
    //request to get license and check its type and subscription period and then displays it based on the type of the first license and its subscription period
    //it will need change in case user can buy many different plan types and subscription periods
    api
      .get("/api/v1/licenses/")
      .then(response => {
        // console.log("davidim", response)
        if (response.data.results[activeOrder].product_features.length === 2) {
          setPlanType("PDF Pro + OCR")
          setChecked(true)
        } else if (
          response.data.results[activeOrder].product_features.length === 1
        ) {
          setPlanType("PDF Pro")
          setChecked(false)
        } else {
          setPlanType("PDF Pro")
          setChecked(false)
        }
        localStorage.setItem(
          "plan",
          JSON.stringify(
            response.data.results[activeOrder].product.product_name
          )
        )
      })
      .catch(err => {
        console.log(err.detail)
        localStorage.clear()
        navigate("/login")
      })
  }, [planType, isLoggedIn, updated])
  //use effect that runs only on first render
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("isLogged")) === true) {
      setUser(JSON.parse(localStorage.getItem("user")).first_name)
    }

    setIsLogged(true)
    api
      .get("/api/v1/licenses/")
      .then(res => {
        console.log("active licenses: ", res)
        setLicenses(res.data)
        let info = JSON.parse(localStorage.getItem("user"))
        if (
          info.true_email.toLowerCase() ===
          res.data.results[activeOrder].customer.email.toLowerCase()
        ) {
          localStorage.setItem("primary", JSON.stringify(true))
          setPrimary(true)
        }
        calculateActiveLicenses(res)

        if (res.data.results.length > 1) {
          for (let i = 0; i < res.data.results.length; i++) {
            if (res.data.results[i].is_trial !== true) {
              localStorage.setItem(
                "orderID",
                JSON.stringify(res.data.results[i])
              )
              setSubscriptionID(res.data.results[i].subscription_id)
              setActiveOrder(i)
              return
            }
          }
        } else if (res.data.results.length === 1) {
          localStorage.setItem("orderID", JSON.stringify(res.data.results[0]))
          setActiveOrder(0)
          setSubscriptionID(res.data.results[0].subscription_id)
          return
        }
        //logic that checks if user is primary administrator
        //setting isLoggedIn global state
        setIsLoggedIn(true)
        if (
          JSON.parse(localStorage.getItem("user")).true_email.toLowerCase() ===
          res.data.results[0].customer.email.toLowerCase()
        ) {
          setGlobalPrimary(true)
          localStorage.setItem("primary", JSON.stringify(true))
        }
      })
      .catch(err => {
        console.log(err)
        localStorage.clear()
        navigate("/login")
      })
  }, [isLoggedIn, planType, updated, globalPrimary])

  //checking number of active licenses
  function calculateActiveLicenses(res) {
    let count = 0
    let trialCount = 0
    for (let i = 0; i < res.data.results.length; i++) {
      if (
        // res.data.results[i].status.toLowerCase() === "active" &&
        res.data.results[i].is_trial !== true
      ) {
        count++
      } else {
        trialCount++
      }
    }
    if (trialCount > count) {
      setActiveLicenses(trialCount)
      setNumber(trialCount)
    } else if (trialCount <= count) {
      setActiveLicenses(count)
      setNumber(count)
    }
  }

  const [now, setNow] = useState(0)
  useEffect(() => {
    if (localStorage.getItem("orderID") && localStorage.getItem("user")) {
      let order = JSON.parse(localStorage.getItem("orderID")).customer
      let user = JSON.parse(localStorage.getItem("user")).true_email

      if (order.email.toLowerCase() === user.toLowerCase()) {
        setGlobalPrimary(true)
      }
    }
    if (globalPrimary !== true) {
      setNow(now + 1)
    }
  }, [globalPrimary, now])

  //special check for insertName popup
  const [visible, setVisible] = useState(undefined)
  //check if user has info - runs only on initial load
  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("username")).first_name === "" &&
      JSON.parse(localStorage.getItem("username")).last_name === "" &&
      JSON.parse(localStorage.getItem("username"))
    ) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [])

  // fix for flickering at loading
  if (isLoadingApp) {
    return <Loader />
  }

  return (
    <>
      <Seo title="Dashborad" noindex={true} />
      {dash && <DasboardMenu number={number} isTrial={isTrial} />}
      {visible && <InsertName exit={() => setVisible(false)} />}
      <CheckoutContext.Provider
        value={{
          subscriptionID,
          setSubscriptionID,
          managers,
          setManagers,
          synchronize,
          setSynchronize,
          administratorChange,
          setAdministratorChange,
          admin,
          setAdmin,
          licenseID,
          setLicenseID,
          license,
          setLicense,
          usedLicenses,
          setUsedLicenses,
          numberOfLicenses,
          setNumberOfLicenses,
          planType,
          setPlanType,
          subscriptionPeriod,
          setSubscriptionPeriod,
          maxUsers,
          setMaxUsers,
          numUser,
          setNumUser,
          primary,
          setPrimary,
          users,
          setUsers,
          sync,
          setSync,
          checkoutDetails,
          setCheckoutDetails,
          isLogged,
          activeLicenses,
          setActiveLicenses,
          number,
          setNumber,
          plan,
          setPlan,
          checked,
          setChecked,
          updateInfo,
          setUpdateInfo,
          isOpen,
          setIsOpen,
          isOcr,
          setIsOcr,
          prices,
          isProrate,
          setIsProrate,
          setUpdated,
          fullPath,
          setFullPath,
          accountID,
          setAccountID,
          payment,
          setPayment,
          card,
          setCard,
          invoices,
          setInvoices,
          invoicesList,
          setInvoicesList,
          isTrial,
          activeOrder,
          setLicenses,
          licenses,
        }}
      >
        <Router>
          <PrivateRoute path="/app/dashboard/" component={Dashboard} />
          {activeLicenses > 1 ? (
            <PrivateRoute path="/app/manage-users/" component={ManageUsers} />
          ) : (
            <Redirect
              from="/app/manage-users"
              to="/app/manage-subscription"
              noThrow
            />
          )}
          {activeLicenses > 1 && globalPrimary ? (
            <PrivateRoute
              path="/app/administrator/"
              component={Administrator}
            />
          ) : (
            <Redirect from="/app/administrator" to="/app/dashboard" noThrow />
          )}

          {!isTrial ? (
            <PrivateRoute
              path="/app/manage-subscription"
              component={ManageSubscription}
            />
          ) : (
            <Redirect from="/app/manage-subscription" to="/checkout" noThrow />
          )}
          <PrivateRoute
            path="/app/password-change/"
            component={PasswordChange}
          />
          {!isTrial ? (
            <PrivateRoute path="/app/billing/" component={Billing} />
          ) : (
            <Redirect from="/app/billing/" to="/app/dashboard/" noThrow />
          )}
          {/* Take all routes that have /app/ and aren't defined to 404 not found page */}
          <PrivateRoute path="/app/*" component={Error}></PrivateRoute>
        </Router>
      </CheckoutContext.Provider>
      <Special
        toggle={toggle}
        onClick={() => {
          if (toggle === true) setToggle(false)
        }}
      ></Special>
    </>
  )
}

export default App

import React, { useContext } from "react"
import styled from "styled-components"
import { CheckoutContext } from "../../Contexts/CheckoutContext"

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  margin-bottom: 24px;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
`
const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 100%;
  transform: translateX(10%);
  position: relative;

  @media (max-width: 1124px) {
    transform: translateX(5%);
    height: max-content;
  }
  @media (max-width: 900px) {
    width: 80%;
  }
`

const TitleHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin: 0px 0px;
  margin-top: 12px;
  margin-bottom: 96px;
  @media (max-width: 1124px) {
    align-items: center;
    width: 100%;
  }
`

const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin: 0px 0px;
  @media (max-width: 1124px) {
    align-items: flex-start;
    width: 100%;
  }
`
const Title = styled.h1`
  width: max-content;
  height: 36px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  color: #000000;
  @media (max-width: 1124px) {
    width: 100%;
  }
  @media (max-width: 475px) {
    font-size: 26px;
    line-height: 32px;
    margin-top: 12px;
    margin-bottom: 8px;
    height: max-content;
    width: 100%;
  }
`
const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 12px 24px;
  height: 42px;
  background: rgb(0, 202, 66);
  border-radius: 60px;
  border: 2px solid rgb(0, 202, 66);
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
  margin: 12px 0px;

  :hover {
    background-color: rgb(50, 205, 50);
    border: 2px solid rgb(50, 205, 50);
    Span {
      color: white;
    }
  }
`

const Span = styled.span`
  width: max-content;
  height: 18px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: white;
  flex: none;
  flex-grow: 0;
`
const BtnHolder = styled.div`
  display: flex;
  gap: 35px;
  width: 60%;
  margin: 0px 0px;
  margin-top: -42px;
  margin-bottom: 24px;
  @media (max-width: 1124px) {
    align-items: center;
    width: 100%;
  }
`
const Btn = styled.button`
  display: inline-block;
  width: fit-content;
  border: none;
  text-decoration: underline;
  background: transparent;
  cursor: pointer;
  color: #007de6;
  :hover {
    color: #008cff;
  }
`
function Container({ children, show, hide, showButton }) {
  const { payment } = useContext(CheckoutContext)
  return (
    <Div>
      <DivContainer>
        <TitleHolder>
          <Title>Billing &amp; Payment</Title>
        </TitleHolder>
        <BtnHolder>
          <Btn onClick={hide}>Payment Profile</Btn>
          <Btn onClick={show}>Invoices</Btn>
        </BtnHolder>
        {children}
        {!showButton && (
          <ButtonHolder>
            <Button href={payment} rel="noopener noreferrer" target="_blank">
              <Span> Update Payment Profile</Span>
            </Button>
          </ButtonHolder>
        )}
      </DivContainer>
    </Div>
  )
}

export default Container

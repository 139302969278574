import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useEffect } from "react"
import Logo from "../icons/logo-vector-white.svg"
import Dashboard from "../icons/dashboard.svg"
import Download from "../icons/download.svg"
import Users from "../icons/groups.svg"
import Administrator from "../icons/person.svg"
import Subscriptions from "../icons/text_snippet.svg"
import Support from "../icons/contact_support.svg"
import LogOut from "../icons/Arrow-Left.svg"

//Context hook
import { useContext } from "react"
import { GlobalAuthContext } from "../Contexts/GlobalAuthContext"
import { billing, checkStatus } from "./helpers/dateFormating"

const DashMobile = styled.button`
  display: none;
  position: fixed;
  width: 40px;
  background: white;
  left: 0px;
  top: 0px;
  bottom: 0px;
  color: gainsboro;
  z-index: 10;
  font-size: 28px;
  border: 1px solid gainsboro;

  @media (max-width: 355px) {
    width: 30px;
    font-size: 21px;
  }

  @media (max-width: 1124px) {
    display: ${({ sideMenu }) => (sideMenu === true ? "none" : "flex")};
    align-items: center;
    justify-content: center;
  }
`
const Special = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  display: none;
  z-index: ${({ sideMenu }) => (sideMenu === true ? 2 : 0)};
  @media (max-width: 1124px) {
    display: ${({ sideMenu }) => (sideMenu === true ? "flex" : "none")};
  }
`

const DashboardMenuStyle = styled.section`
  position: fixed;
  width: 260px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #444444;
  z-index: 13;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;

  @media (max-width: 1124px) {
    display: ${({ sideMenu }) => (sideMenu === false ? "none" : "flex")};
  }

  .menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;

    .plusplus {
      height: fit-content;
    }

    .img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 99px;
      margin-bottom: 12px;
      @media (max-width: 768px) {
        height: 86px;
      }
      @media (max-width: 475px) {
        height: 80px;
      }
    }

    .text {
      width: fit-content;
      height: 24px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.5px;
      color: #ffffff;
      margin: 0px 16px;
    }
    .text-sub {
      width: 120px;
      height: 24px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.5px;
      color: #ffffff;
      margin: 0px 12px;
    }
    .text-sub-active {
      width: 120px;
      height: 24px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.5px;
      color: rgb(228, 67, 49);
      margin: 0px 12px;
    }
    .text-special {
      width: 100%;
      height: fit-content;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.5px;
      color: #ffffff;
      margin: 0px 16px;
    }
    .links {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 60px;
      width: 100%;
      text-decoration: none;
      padding-left: 65px;
      &:hover {
        background: #e44331;

        .icon-img {
          fill: white;
        }
      }
    }
  }

  .links-sub {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
    text-decoration: none;
    padding-left: 65px;
    &:hover {
      background: rgb(196, 116, 108);

      .icon-img {
        fill: white;
      }
      .active-sub {
        fill: white;
      }
      .text-sub-active {
        color: white;
        font-weight: normal;
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    background-color: #444444;
    padding: 0;
    box-shadow: none;
    justify-self: end;
    height: 72px;
    width: 100%;
    padding-left: 65px;
    text-decoration: none;
    color: white;

    &:hover {
      background: #e44331;

      .icon-img {
        fill: white;
        stroke: white;
      }
    }

    .button-text {
      width: 83px;
      height: 24px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.5px;
      color: #ffffff;
      margin: 0px 16px;
    }
  }
  .icon-img {
    fill: #999999;
  }

  .active {
    fill: white;
  }
  .active-sub {
    fill: rgb(228, 67, 49);
  }

  .holder {
    display: flex;
    align-items: center;
    height: 100%;
  }
`

function DasboardMenu(props) {
  //basic way to keep track which tab is open
  let active
  let sub

  if (typeof window !== "undefined") {
    if (
      window.location.pathname === "/app/dashboard" ||
      window.location.pathname === "/app/dashboard/"
    ) {
      active = 1
      sub = 1
    } else if (
      window.location.pathname === "/app/manage-users" ||
      window.location.pathname === "/app/manage-users/"
    ) {
      active = 3
      sub = 3
    } else if (
      window.location.pathname === "/app/administrator" ||
      window.location.pathname === "/app/administrator/"
    ) {
      active = 4
      sub = 4
    } else if (
      window.location.pathname === "/app/manage-subscription" ||
      window.location.pathname === "/app/manage-subscription/"
    ) {
      active = 5
      sub = 5
    } else if (
      window.location.pathname === "/app/billing" ||
      window.location.pathname === "/app/billing/"
    ) {
      active = 6
      sub = 6
    }
  }

  //global context hook
  const {
    setAuthNumber,
    globalPrimary,
    setGlobalPrimary,
    toggle,
    setToggle,
    sideMenu,
    setSideMenu,
  } = useContext(GlobalAuthContext)

  //useffect hook
  useEffect(() => {
    if (globalPrimary === null) {
      setAuthNumber(JSON.parse(localStorage.getItem("numberOfLicenses")))
      setGlobalPrimary(JSON.parse(localStorage.getItem("primary")))
    }
  }, [globalPrimary])

  return (
    <>
      <DashMobile
        sideMenu={sideMenu}
        onClick={() => {
          setSideMenu(true)
        }}
      >
        &gt;&gt;
      </DashMobile>
      <Special
        sideMenu={sideMenu}
        onClick={() => {
          setSideMenu(false)
        }}
      />
      <DashboardMenuStyle
        sideMenu={sideMenu}
        onClick={() => {
          if (toggle === true) setToggle(false)
        }}
      >
        <div className="menu-container">
          <div className="img-container">
            <Link to="/">
              <Logo className="icon-img" />
            </Link>
          </div>
          <div className="menu-container plusplus">
            {active === 1 && (
              <Link
                className="links"
                style={{ backgroundColor: `#e44331` }}
                to="/app/dashboard"
              >
                <div className="holder">
                  <Dashboard className="active" />
                  <span className="text"> Dashboard</span>
                </div>
              </Link>
            )}
            {active !== 1 && (
              <Link className="links" to="/app/dashboard">
                <div className="holder">
                  <Dashboard className="icon-img" />
                  <span className="text"> Dashboard</span>
                </div>
              </Link>
            )}
            <Link className="links" to="/download">
              <Download className="icon-img" />
              <span className="text"> Download</span>
            </Link>
            {billing() !== "Trial" &&
              checkStatus() === true &&
              active >= 3 &&
              sub >= 3 && (
                <Link
                  className="links"
                  to="/app/manage-users"
                  style={{ backgroundColor: `#e44331` }}
                >
                  <Users className="active" />
                  <span className="text-special">
                    Account &amp; Subscriptions
                  </span>
                </Link>
              )}

            {billing() !== "Trial" &&
              checkStatus() === true &&
              active <= 3 &&
              sub < 3 && (
                <Link className="links" to="/app/manage-users">
                  <Users className="icon-img" />
                  <span className="text-special">
                    Account &amp; Subscriptions
                  </span>
                </Link>
              )}

            {active === 3 && props.number > 1 && sub >= 3 && (
              <Link className="links-sub" to="/app/manage-users">
                <Users className="active-sub" />
                <span className="text-sub-active"> Users</span>
              </Link>
            )}
            {active !== 3 && props.number > 1 && sub >= 3 && (
              <Link className="links-sub" to="/app/manage-users">
                <Users className="icon-img" />
                <span className="text-sub"> Users</span>
              </Link>
            )}
            {active === 4 && props.number > 1 && globalPrimary && sub >= 3 && (
              <Link className="links-sub" to="/app/administrator">
                <Administrator className="active-sub" />
                <span className="text-sub-active"> Administrator</span>
              </Link>
            )}
            {active !== 4 && props.number > 1 && globalPrimary && sub >= 3 && (
              <Link className="links-sub" to="/app/administrator">
                <Administrator className="icon-img" />
                <span className="text-sub"> Administrator</span>
              </Link>
            )}
            {active === 5 && sub >= 3 && (
              <Link className="links-sub" to="/app/manage-subscription">
                <Subscriptions className="active-sub" />
                <span className="text-sub-active"> Subscription</span>
              </Link>
            )}
            {active !== 5 && sub >= 3 && (
              <Link className="links-sub" to="/app/manage-subscription">
                <Subscriptions className="icon-img" />
                <span className="text-sub"> Subscription</span>
              </Link>
            )}
            {active === 6 && sub >= 3 && (
              <Link className="links-sub" to="/app/billing">
                <LogOut className="active-sub" />
                <span className="text-sub-active"> Billing</span>
              </Link>
            )}
            {active !== 6 && sub >= 3 && (
              <Link className="links-sub" to="/app/billing">
                <LogOut className="icon-img" />
                <span className="text-sub"> Billing</span>
              </Link>
            )}
            {active === 7 && (
              <a
                className="links"
                target="_blank"
                rel="noreferrer noopener"
                href="https://support.pdfpro.com/hc/en-us/requests/new"
              >
                <Support className="active" />
                <span className="text"> Support</span>
              </a>
            )}
            {active !== 7 && (
              // when you get link you will change div to Link
              <a
                className="links"
                target="_blank"
                rel="noreferrer noopener"
                href="https://support.pdfpro.com/hc/en-us/requests/new"
              >
                <Support className="icon-img" />
                <span className="text"> Support</span>
              </a>
            )}
          </div>
        </div>
        {/* <button
          onClick={() => {
            localStorage.clear()
            setNumber(null)
            setUser("")
            setLoader(undefined)
            setActiveLicenses(null)
            setPlan(null)
            setPlanType("")
            setToggle(false)
            setSubscriptionPeriod("")
            setIsLoggedIn(null)
            navigate("/login/")
          }}
          type="button"
        >
          <LogOut className="icon-img" />
          <span className="button-text"> Log out</span>
        </button> */}
      </DashboardMenuStyle>
    </>
  )
}

export default DasboardMenu

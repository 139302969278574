import React from "react"
import Seo from "../../components/seo"
import { useState } from "react"
import Container from "../../components/dashboard/container"
//context hook
import { CheckoutContext } from "../../Contexts/CheckoutContext"
import { useContext } from "react"
import UpgradePopup from "../../components/manage-users/upgradePopup"
import UserInformation from "../../components/dashboard/userInformation"

function Dashboard() {
  //state that regulates popup for upgrade/downgrade subscription
  const [openPopUp, setOpenPopUp] = useState(false)

  // useEffect kojem je drugi arg [], ovaj api call se loada samo jednom dok se stranica rendera ili rerendera!

  //Context Hooks
  const { isTrial, isLogged } = useContext(CheckoutContext)

  function open() {
    setOpenPopUp(true)
  }
  function close() {
    setOpenPopUp(false)
  }
  return (
    <>
      <Seo title="User Dashboard" noindex={true} />
      {isLogged && (
        <>
          <Container open={open} close={close} isTrial={isTrial}>
            <UserInformation />
          </Container>
          {openPopUp && <UpgradePopup hide={close} />}
        </>
      )}
    </>
  )
}

export default Dashboard

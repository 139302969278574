import React from "react"
import styled from "styled-components"
import UniversalButton from "./universalButton"
import { useState, useContext } from "react"
import axios from "axios"
import Close from "../../icons/close-icon-popup.svg"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
//helper function
import { updateCustomer } from "../helpers/backendCalls"

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  pointer-events: none;

  /* Close button icon CSS */
  .close-button {
    height: 12px;
    width: 12px;
    position: absolute;
    top: 25px;
    right: 45px;
    cursor: pointer;
  }
`

const PopupWindow = styled.div`
  display: ${({ success }) => (success ? "none" : "flex")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 520px;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  pointer-events: auto;
  position: relative;
  @media (max-width: 580px) {
    width: 90%;
  }
`
const Title = styled.h1`
  width: 384px;
  height: 40px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #111111;
  margin: 32px 0px;
  @media (max-width: 580px) {
    width: 90%;
  }
`
const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0px;
  width: 384px;
  height: max-content;
  @media (max-width: 580px) {
    width: 90%;
  }
`
const Row = styled.div`
  width: 100%;
  height: 77px;
  margin: 16px 0px;
`
const Label = styled.label`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
  margin: 8px 0px;
`
const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  height: 51px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 8px 0px;
  ::placeholder {
    width: max-content;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #999999;
    text-indent: 10px;
  }
`

const SuccessContainer = styled.div`
  display: ${({ success }) => (success ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 250px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 24px;
  pointer-events: auto;
  position: relative;
  @media (max-width: 580px) {
    width: 330px;
  }
`

const SuccessMessage = styled.h1`
  width: 80%;
  height: 72px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #222222;
  margin: 24px 0px;
  @media (max-width: 580px) {
    font-size: 18px;
    line-height: 32px;
  }

  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    @media (max-width: 580px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
`
const WrongEmail = styled.p`
  visibility: ${({ fail }) => (fail ? "visible" : "hidden")};
  font-family: Work Sans;
  width: 384px;
  height: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #e44331;
  margin: 0;
  padding: 0;
  align-self: flex-start;
  @media (max-width: 675px) {
    width: 273px;
    overflow: hidden;
  }
`

function InsertName({ exit }) {
  const [isDisabled, setIsDisabled] = useState(false)
  const [name, setName] = useState("")
  const [lname, setLname] = useState("")
  const [success, setSuccess] = useState(false)
  const [fail, setFail] = useState(false)

  //global context
  const { setUser } = useContext(GlobalAuthContext)

  //handle submit
  function HandleSubmit() {
    setIsDisabled(true)
    let userID = JSON.parse(localStorage.getItem("user")).id
    if (name.length > 2 && lname.length > 2) {
      axios
        .patch(`https://backend.pdfpro.com/api/license/users/${userID}`, {
          first_name: name,
          last_name: lname,
        })
        .then(res => {
          // console.log(res)
          setUser(name)
          localStorage.removeItem("username")
          localStorage.setItem(
            "username",
            JSON.stringify({ first_name: name, last_name: lname })
          )
          setSuccess(true)
          updateCustomer({
            customerID: JSON.parse(localStorage.getItem("orderID")).customer.id,
            userEmail: JSON.parse(localStorage.getItem("user")).true_email,
            customerEmail: JSON.parse(localStorage.getItem("orderID")).customer
              .email,
            name: name,
            lname: lname,
          })
          setIsDisabled(false)
        })
        .catch(err => {
          console.log(err)
          setIsDisabled(false)
        })
    } else {
      setFail(true)
      setIsDisabled(false)
    }
  }
  return (
    <Div>
      <SuccessContainer success={success}>
        <Close className="close-button" onClick={exit} />
        <SuccessMessage>Hello {name}!</SuccessMessage>
      </SuccessContainer>
      <PopupWindow success={success}>
        <Close className="close-button" onClick={exit} />
        <Title>What should we call you?</Title>
        <Group>
          <Row>
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              name="name"
              placeholder="Enter Name*"
              onChange={e => setName(e.target.value)}
            ></Input>
          </Row>
          <Row>
            <Label htmlFor="lname">Last Name</Label>
            <Input
              type="text"
              name="lname"
              placeholder="Enter Last Name*"
              onChange={e => setLname(e.target.value)}
            ></Input>
          </Row>
          <WrongEmail fail={fail}>Insert valid user information!</WrongEmail>
          <UniversalButton
            size="big"
            disabled={isDisabled}
            onClick={HandleSubmit}
          >
            Save
          </UniversalButton>
        </Group>
      </PopupWindow>
    </Div>
  )
}

export default InsertName

import React, { useContext } from "react"
import styled from "styled-components"
import { navigate } from "@reach/router"
import UpgradeButton from "../../components/shared/upgradeButton"
import AccountStatus from "./accountStatus"
import { CheckoutContext } from "../../Contexts/CheckoutContext"
import { billing, checkStatus } from "../helpers/dateFormating"

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  margin-bottom: 24px;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
`
const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 100%;
  transform: translateX(10%);
  position: relative;

  @media (max-width: 1124px) {
    transform: translateX(5%);
    height: max-content;
  }
  @media (max-width: 900px) {
    width: 80%;
  }
`
const Holder = styled.div`
  display: flex;
  width: 70%;
  transform: translateX(10%);
  @media (max-width: 1124px) {
    transform: translateX(5%);
    height: max-content;
  }
  @media (max-width: 900px) {
    transform: translateX(0%);
    width: 80%;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  width: 80%;
  transform: translateX(10%);
  box-sizing: border-box;
  @media (max-width: 900px) {
    transform: translateX(5%);
    width: 100%;
  }
`

function Container({ children, isTrial, open }) {
  const { activeLicenses } = useContext(CheckoutContext)
  return (
    <Div>
      <Holder>
        <ButtonContainer />
      </Holder>
      <AccountStatus />
      <DivContainer>{children}</DivContainer>
      <Holder>
        <ButtonContainer>
          {isTrial === false && (
            <UpgradeButton
              className="upgradePlan"
              name="Manage Account &amp; Subscription"
              action={() => {
                navigate("/app/manage-users")
              }}
            />
          )}
          {billing() !== "Trial" &&
            checkStatus() !== false &&
            activeLicenses < 2 && (
              <UpgradeButton
                className="upgradeOrganisation"
                name="Upgrade to increase user limit"
                action={open}
              />
            )}
        </ButtonContainer>
      </Holder>
    </Div>
  )
}

export default Container

import React from "react"
import styled from "styled-components"
import { useContext } from "react"
import { CheckoutContext } from "../../Contexts/CheckoutContext"
import InvoiceIcon from "../../icons/invoice-icon.svg"

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 450px;
  /* border: 3px solid #2222; */
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 15%);
  overflow-y: scroll;
  .pushed-down {
    margin: 24px 0px;
  }
  .button-holder {
    justify-content: center;
  }
  .icon-invoice {
    margin-right: 10px;
    @media (max-width: 700px) {
      margin-right: 0px;
    }
  }
  @media (max-width: 1380px) {
    width: 100%;
    height: max-content;
  }
`

const Text = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 0.5px;
  color: #111111;
  margin: 4px 10px;
  width: 50%;
  @media (max-width: 700px) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 430px) {
    font-size: 16px;
    line-height: 32px;
  }
  @media (max-width: 330px) {
    font-size: 14px;
    line-height: 30px;
  }
`
const Text1 = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  justify-content: flex-start;
  letter-spacing: 0.5px;
  color: #111111;
  margin: 4px 0px;
  width: max-content;
  @media (max-width: 700px) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 430px) {
    font-size: 16px;
    line-height: 32px;
  }
  @media (max-width: 330px) {
    font-size: 14px;
    line-height: 30px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

function Invoices() {
  const { invoicesList } = useContext(CheckoutContext)
  function showAllInvoices() {
    return invoicesList.map(invoice => {
      return (
        <Row key={invoice.id}>
          <Text>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={invoice.invoiceUrl}
            >
              Invoice {invoice.reference}
            </a>
          </Text>
          <Text1>{invoice.time}</Text1>
          <Text1>
            <InvoiceIcon
              className="icon-invoice"
              style={{ fill: "black", height: "32px", width: "32px" }}
            />
          </Text1>
        </Row>
      )
    })
  }
  return (
    <>
      <Holder>{showAllInvoices()}</Holder>
    </>
  )
}

export default Invoices

import React from "react"
import styled from "styled-components"
import { useContext, useEffect, useState } from "react"
import { CheckoutContext } from "../../Contexts/CheckoutContext"
import {
  checkStatus,
  formatDate,
  formatDateEnd,
  timeLeft,
  trialDuration,
  isTrial,
  trialLeft,
} from "../helpers/dateFormating"

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  color: #111111;
  margin: 16px 0px;
  gap: 10px;
  /* border: 3px solid #2222; */
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 15%);
  padding: 10px;
  @media (max-width: 900px) {
    width: 100%;
  }
`
const Text = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  letter-spacing: 0.5px;
  color: #111111;
  margin: 4px 10px;
  width: 40%;
  @media (max-width: 1300px) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
`

const Text1 = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  justify-content: flex-start;
  letter-spacing: 0.5px;
  color: #111111;
  margin: 4px 0px;
  @media (max-width: 1300px) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 1300px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const DaysRemaining = styled.span`
  font-family: Work Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  color: #999999;
  margin: 0px 10px;
  @media (max-width: 1300px) {
    margin: 0px 5px;
  }
  @media (max-width: 600px) {
    display: block;
  }
`
function UserInformation() {
  //variable that stores number of active licenses
  const { activeLicenses, planType, licenses } = useContext(CheckoutContext)
  const [memberSince, setMemberSince] = useState("")
  const [validUntil, setValidUntil] = useState("")
  const [remainingTime, setRemainingTime] = useState("")
  const [currentTrialDuration, setCurrentTrialDuration] = useState("")
  const [trialRemaining, setTrialRemaining] = useState("")
  useEffect(() => {
    if (activeLicenses > 0) {
      setMemberSince(formatDate())
    }
    setValidUntil(formatDateEnd())
    setRemainingTime(timeLeft())
    setCurrentTrialDuration(trialDuration())
    setTrialRemaining(trialLeft())
  }, [])

  console.log("Licenses: ", licenses)
  return (
    <Holder>
      {checkStatus() === true && (
        <Row>
          <Text>Member of PDF Pro since:</Text>
          <Text1>
            <strong>{memberSince}</strong>
          </Text1>
        </Row>
      )}
      <Row>
        <Text>Plan Type:</Text>
        {checkStatus() && (
          <Text1>
            <strong>
              {isTrial() === false && planType}
              {isTrial() && <>Trial</>}
            </strong>
          </Text1>
        )}
        {checkStatus() === false && (
          <Text1>
            <strong>-</strong>
          </Text1>
        )}
      </Row>
      <Row>
        <Text>Account Type:</Text>
        <Text1>
          <strong>
            {activeLicenses > 1 && <>Multi User Account</>}
            {activeLicenses < 2 && <>Single User Account</>}
          </strong>
        </Text1>
      </Row>
      <Row>
        <Text>Plan Valid Until:</Text>

        {checkStatus() && (
          <Text1>
            <strong>
              {isTrial() === false && validUntil}
              {isTrial() === true && currentTrialDuration}
              <DaysRemaining>
                {isTrial() === false && remainingTime}
                {isTrial() === true && trialRemaining}
              </DaysRemaining>
            </strong>
          </Text1>
        )}
        {checkStatus() === false && <Text1>-</Text1>}
      </Row>
    </Holder>
  )
}

export default UserInformation

import React from "react"
import styled from "styled-components"
import api from "../../components/api"
import { useState } from "react"
import Seo from "../../components/seo"
//context hook

import { useEffect } from "react"
import CardContainer from "../../components/register-login-reset-popup/cardContainer"
import FormStyle from "../../components/register-login-reset-popup/styles/formStyle"
import { useForm } from "react-hook-form"
import FormTitleStyle from "../../components/register-login-reset-popup/styles/formTitleStyle"
import Loader from "../../components/shared/loader"
import Alert from "../../components/register-login-reset-popup/alert"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Style = styled.section`
  font-family: "Work Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 81.1vh;

  .loader-box {
    width: 300px;
  }

  .success-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 376px;
    background: #ffffff;

    @media (max-width: 560px) {
      width: 100%;
    }

    .success-name {
      width: 305px;
      height: 40px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 40px;
      text-align: center;
      color: #111111;
      flex: none;
      flex-grow: 0;
      margin: 24px 0px;
    }

    .success-text {
      width: 305px;
      height: 54px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #222222;
      flex: none;
      align-self: center;
      flex-grow: 0;
      margin: 24px 0px;
    }
  }
`

function ChangePass() {
  //user auth context states
  const [pass, setPass] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [fail, setFail] = useState(false)
  const [load, setLoad] = useState(false)

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      OldPassword: null,
      NewPassword: null,
      NewPasswordRepeat: null,
    },
  })

  // This is necessary to block send button from sending multiple requests
  useEffect(() => {
    console.log("PROSLOOOO")
  }, [isSubmitSuccessful])

  const onSubmit = data => {
    setDisabled(true)
    setFail(false)
    setLoad(true)

    if (data.OldPassword === "") {
      setFail(true)
      setError("OldPassEmpty", {
        type: "Empty",
        message: "Old password required",
      })
    }

    if (data.NewPassword === "") {
      setFail(true)

      setError("NewPassEmpty", {
        type: "Empty",
        message: "New password required",
      })
    }

    if (data.NewPasswordRepeat === "") {
      setFail(true)

      setError("RepeatPassEmpty", {
        type: "Empty",
        message: "Repeat password required",
      })
    }

    if (data.NewPasswordRepeat !== data.NewPassword) {
      setFail(true)
      setError("NotMatching", {
        type: "NotMatching",
        message: "Passwords are not the same",
      })
    }

    if (data.NewPassword.length < 6) {
      setFail(true)
      setError("ToShort", {
        type: "ToShort",
        message: "Password is to short(6 signs min)",
      })
    }
    if (!fail) {
      api
        .post(`/api/v1/rest-auth/password/change/`, {
          old_password: data.OldPassword,
          new_password1: data.NewPassword,
          new_password2: data.NewPassword,
        })
        .then(res => {
          console.log("PROSLOOOOOO", res)
          setLoad("Succes")
        })
        .catch(err => {
          console.log(err)
          setFail(true)
          setDisabled(false)
          setLoad(false)

          setError("NonExisting", {
            type: "NonExisting",
            message: (
              <>
                Old password is wrong,{" "}
                <Link to="/password-reset">reset password here</Link>
              </>
            ),
          })
        })
    } else {
      setDisabled(false)
      setLoad(false)
    }
  }
  return (
    <Style>
      <Seo title="Change Password" />
      <CardContainer>
        <FormStyle>
          {load !== "Succes" && !load && (
            <form autocomplete="off" onSubmit={handleSubmit(onSubmit)}>
              <FormTitleStyle>Select New Password</FormTitleStyle>

              <label htmlFor="OldPassword">Old Password:</label>
              <input
                type="password"
                id="OldPassword"
                name="OldPassword"
                autocomplete="off"
                placeholder="Enter old password"
                required
                {...register("OldPassword", {
                  required: "Old password required",
                  onChange: () => {
                    clearErrors("OldPassEmpty")
                    clearErrors("NonExisting")
                  },
                })}
              />

              <label htmlFor="NewPassword">New Password:</label>
              <input
                type="password"
                id="NewPassword"
                name="NewPassword"
                autocomplete="off"
                placeholder="Enter new password"
                required
                {...register("NewPassword", {
                  required: "New password required",
                  onChange: e => {
                    setPass(pas => (pas = e.target.value))
                    if (e.target.value.length >= 6) {
                      clearErrors("ToShort")
                    }
                    clearErrors("NewPassEmpty")
                  },
                })}
              />

              <label htmlFor="NewPasswordRepeat">Confirm New Password:</label>
              <input
                type="password"
                id="NewPasswordRepeat"
                name="NewPasswordRepeat"
                autocomplete="off"
                placeholder="Repeat new password"
                required
                {...register("NewPasswordRepeat", {
                  required: "Repeat password required",
                  onChange: e => {
                    clearErrors("RepeatPassEmpty")
                    if (e.target.value === pass) {
                      clearErrors("NotMatching")
                    }
                  },
                })}
              />
              {Object.values(errors).map(prop => {
                return <Alert>{prop.message}</Alert>
              })}

              <input
                className="submit"
                type="submit"
                value="Complete Registration"
                disabled={disabled}
              />
            </form>
          )}
          {load !== "Succes" && load && (
            <div className="loader-box">
              <Loader />
            </div>
          )}

          {load === "Succes" && (
            <div className="success-box">
              <StaticImage
                src="../../images/success-img.png"
                width={33}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "png"]}
                loading="eager"
                placeholder="blurred"
                alt="success-img"
              />
              <h2 className="success-name">Password changed!</h2>
            </div>
          )}
        </FormStyle>
      </CardContainer>
    </Style>
  )
}

export default ChangePass

import React, { useState, useContext } from "react"
import Seo from "../../components/seo"
import Container from "../../components/billing/container"
import PaymentInfo from "../../components/billing/paymentinfo"
import Invoices from "../../components/billing/invoices"
import { CheckoutContext } from "../../Contexts/CheckoutContext"

function Billing() {
  const [showInvoice, setShowInvoice] = useState(false)
  const { isLogged } = useContext(CheckoutContext)

  function show() {
    setShowInvoice(true)
  }
  function hide() {
    setShowInvoice(false)
  }
  return (
    <>
      <Seo title="Billing and Payment Profile Page" noindex={true} />
      {isLogged && (
        <Container show={show} hide={hide} showButton={showInvoice}>
          {!showInvoice && <PaymentInfo />}
          {showInvoice && <Invoices />}
        </Container>
      )}
    </>
  )
}

export default Billing

import React, { useContext } from "react"
import styled from "styled-components"
import { CheckoutContext } from "../../Contexts/CheckoutContext"

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: max-content;
  /* border: 3px solid #2222; */
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 15%);
  .pushed-down {
    margin: 24px 0px;
  }
  .button-holder {
    justify-content: center;
  }
  @media (max-width: 1124px) {
    width: 100%;
    height: max-content;
  }
`
const Text = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  letter-spacing: 0.5px;
  color: #111111;
  margin: 4px 10px;
  width: 48%;
  @media (max-width: 450px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`
const Text1 = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  justify-content: flex-start;
  letter-spacing: 0.5px;
  color: #111111;
  margin: 4px 0px;
  @media (max-width: 450px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

function PaymentInfo() {
  const { card } = useContext(CheckoutContext)
  return (
    <Holder>
      <Row>
        <Text>Credit Card:</Text>
        <Text1>
          {typeof window !== "undefined" ? card.type : "Not provided"}
        </Text1>
      </Row>
      <Row>
        <Text>Card Ending in:</Text>
        <Text1>
          {typeof window !== "undefined" ? card.ending : "Not provided"}
        </Text1>
      </Row>

      <Row>
        <Text>ZIP/Postal Code:</Text>
        <Text1>
          {typeof window !== "undefined" ? card.postal : "Not provided"}
        </Text1>
      </Row>
      <Row className="pushed-down">
        <Text>Tax Applied:</Text>
        <Text1>
          {typeof window !== "undefined" ? `${card.tax}%` : "Not provided"}
        </Text1>
      </Row>
    </Holder>
  )
}

export default PaymentInfo
